<template>
  <div>

    <b-row>
      <b-col md="12">
        <div class="card p-2 bg-blue">
          <div class="d-flex justify-content-between align-items-center">
            <h3 class="mb-0 text-white">
              {{ $t('orderList') }}
            </h3>
          </div>

          <div class="card p-2 mt-1">
            <div class="anan-order">
              <div class="row">
                <div class="col-md-6">
                  <table>
                    <tbody>
                      <tr class="anan-order-row">
                        <td
                          class=""
                          style="width: 40%;"
                        >
                          <p class="txt-odr mb-0">
                            {{ $t('orderNumber') }}
                          </p>
                        </td>
                        <td
                          class="text-left pl-1"
                          style="width: 60%;"
                        >
                          <p class="txt-odr mb-0">
                            ORD-{{ details.orderId_text }}
                          </p>
                        </td>
                      </tr>
                      <tr class="anan-order-row">
                        <td
                          class=""
                          style="width: 40%;"
                        >
                          <p class="txt-odr mb-0">
                            Ref.
                          </p>
                        </td>
                        <td
                          class="text-left pl-1"
                          style="width: 60%;"
                        >
                          <p class="txt-odr mb-0">
                            {{ details.username }}#{{ details.orderId_text }} {{ details.delivery_type == 1 ? '' :
                              '(SEA)' }}
                          </p>
                        </td>
                      </tr>

                      <tr class="anan-order-row">
                        <td
                          class=""
                          style="width: 40%;"
                        >
                          <p class="txt-odr mb-0">
                            {{ $t('customerCode') }} :
                          </p>
                        </td>
                        <td
                          class="text-left pl-1"
                          style="width: 60%;"
                        >
                          <p class="txt-odr mb-0">
                            <b-link :to="{name : 'admin-customer-detail'}">
                              {{ details.username }}
                            </b-link>
                          </p>
                        </td>
                      </tr>

                      <tr class="anan-order-row">
                        <td style="width: 40%;">
                          <p class="txt-odr mb-0">
                            {{ $t('mnemonic') }} :
                          </p>
                        </td>
                        <td
                          class="text-left pl-1"
                          style="width: 60%;"
                        >
                          <p class="txt-odr mb-0">
                            {{ details.note }}
                          </p>
                        </td>
                      </tr>

                      <tr class="anan-order-row">
                        <td style="width: 40%;">
                          <p class="txt-odr mb-0">
                            {{ $t('mnemonicPhrase') }} :
                          </p>
                        </td>
                        <td
                          class="text-left pl-1"
                          style="width: 60%;"
                        >
                          <p class="txt-odr mb-0">
                            {{ details.note_admin }}
                          </p>
                        </td>
                      </tr>

                      <tr class="anan-order-row">
                        <td
                          class=""
                          style="width: 40%;"
                        >
                          <p class="txt-odr mb-0">
                            {{ $t('deliveryType') }} :
                          </p>
                        </td>
                        <td
                          class="text-left pl-1"
                          style="width: 60%;"
                        >
                          <div v-if="details.delivery_type == 2">
                            {{ $t('byship') }} <i class="fas fa-ship" />
                          </div>

                          <div v-else>
                            {{ $t('byTruck') }} <i class="fas fa-shipping-fast" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="col-md-6">
                  <table>
                    <tbody>
                      <tr class="anan-order-row">
                        <td
                          class="text-right"
                          style="width: 40%;"
                        >
                          <p class="txt-odr mb-0">
                            {{ $t('status') }}
                          </p>
                        </td>
                        <td
                          class="text-left pl-1"
                          style="width: 60%;"
                        >
                          <p class="txt-odr mb-0">
                            <b-badge
                              v-if="details.status === 1"
                              variant="light-warning"
                            >
                              {{ $t('pendingReview') }}
                            </b-badge>

                            <b-badge
                              v-if="details.status === 2"
                              variant="light-danger"
                            >
                              {{ $t('awaitingPayment') }}
                            </b-badge>

                            <b-badge
                              v-if="details.status === 3"
                              variant="light-info"
                            >
                              {{ $t('paidAmount') }}
                            </b-badge>

                            <b-badge
                              v-if="details.status === 4"
                              variant="light-success"
                            >
                              {{ $t('orderSuccess') }}
                            </b-badge>

                            <b-badge
                              v-if="details.status === 5"
                              variant="light-info"
                            >
                              {{ $t('fullTrack') }}
                            </b-badge>

                            <b-badge
                              v-if="details.status === 6"
                              variant="light-info"
                            >
                              {{ $t('completeWarehouse') }}
                            </b-badge>

                            <b-badge
                              v-if="details.status === 7"
                              variant="light-danger"
                            >
                              {{ $t('cancel') }}
                            </b-badge>
                          </p>
                        </td>
                      </tr>

                      <tr class="anan-order-row">
                        <td
                          class="text-right"
                          style="width: 40%;"
                        >
                          <p class="txt-odr mb-0">
                            {{ $t('transactionDate') }}
                          </p>
                        </td>
                        <td
                          class="text-left pl-1"
                          style="width: 60%;"
                        >
                          <p class="txt-odr mb-0">
                            {{ details.created_at }}
                          </p>
                        </td>
                      </tr>

                      <tr class="anan-order-row">
                        <td
                          class="text-right"
                          style="width: 40%;"
                        >
                          <p class="txt-odr mb-0">
                            {{ $t('exRate') }}
                          </p>
                        </td>
                        <td
                          class="text-left pl-1"
                          style="width: 60%;"
                        >
                          <p class="txt-odr mb-0">
                            ¥1 = {{ details.rate_yuan }} ฿
                            <!-- <span>
                              <feather-icon
                                icon="Edit3Icon"
                                size="18"
                                class="cursor-pointer text-primary"
                                @click="showModal"
                              />
                            </span> -->
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="col-md-6 my-2 px-2">
                  <h5>{{ $t('orderNoteUser') }}</h5>

                  <b-form-textarea
                    v-model="details.remark_customer"
                    row="2"
                    disabled
                  />
                </div>

                <div class="col-md-6 my-2 px-2">
                  <h5>{{ $t('orderNoteAdmin') }}</h5>

                  <b-form-textarea
                    v-model="details.remark_admin"
                    row="2"
                    disabled
                  />
                </div>

                <div class="col-md-12">
                  <p>
                    {{ $t('inspector') }} : <b-link :to="{name : 'admin-customer-detail'}">
                      {{ details.inspector ? details.inspector: '-' }}
                    </b-link>
                  </p>
                  <p>
                    {{ $t('lastEditedBy') }} : <b-link :to="{name : 'admin-customer-detail'}">
                      {{ details.edit_by ? details.edit_by: '-' }}
                    </b-link>
                  </p>

                  <p>
                    {{ $t('lastEditDate') }} : {{ details.updated_at }}
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </b-col>

      <b-col md="12">
        <div class="card">
          <div class="header-cardx p-2">
            <h3 class="mb-0">
              {{ $t('relatedAmountList') }}
            </h3>
          </div>

          <div class="pl-2 pr-2">
            <b-table
              responsive="sm"
              :fields="fieldsRelevant"
              :items="itemRelevant"
              show-empty
            >
              <template #empty>
                <b-table-empty-slot>
                  <div class="text-center my-2">
                    <img
                      src="@/assets/images/anan-img/svg/doc_empty.svg"
                      alt="empty"
                      height="60px"
                    >
                    <p class="text-muted">
                      No Data
                    </p>
                  </div>
                </b-table-empty-slot>
              </template>
              <template #cell(amount)="data">
                <span :style="getAmountStyle(data.item.type, data.item.type_amount)">
                  {{ getAmountPrefix(data.item.type, data.item.type_amount) }} {{ Commas(data.item.amount.toFixed(2)) }} ฿
                </span>

              </template>
            </b-table>
          </div>
        </div>
      </b-col>

      <b-col md="12">
        <div class="card p-2">

          <h3 class="mb-0 font-weight-bolder">
            {{ $t('listProducts') }}
          </h3>

          <div
            v-for="(item,index) in details.shops"
            :key="item"
            class="mb-2 table-container"
          >
            <div>

              <div class="mt-1">
                <table style="width: 1000px;">
                  <tbody>
                    <tr class="anan-order-row">
                      <td style="width: 25%;">
                        <p class="txt-odrx mb-0">
                          {{ $t('productName') }} / {{ $t('productDetails') }}
                        </p>
                      </td>

                      <!-- <td
                    class=""
                    style="width: 25%;"
                  >
                    <p class="txt-odrx mb-0 d-flex align-items-center">
                      <span class="mr5">ประเภท</span>
                      <b-form-select
                        v-model="type"
                        :options="typesOp"
                        size="sm"
                        class="w-25"
                      />
                    </p>
                  </td> -->

                      <td
                        class=""
                        style="width: 10%;"
                      >
                        <b-form-checkbox
                          v-model="item.packbox"
                          name="check-button"
                          :value="true"
                          disabled
                          @change="UpdateDetail(details.shops[index].packbox)"
                        >
                          <p class="txt-odrx mb-0">
                            {{ $t('woodPacking') }}
                          </p>
                        </b-form-checkbox>
                      </td>

                      <td
                        class=""
                        style="width: 10%;"
                      >
                        <b-form-checkbox
                          v-model="item.checkproduct"
                          name="check-button"
                          :value="true"
                          disabled
                          @change="UpdateDetail(details.shops[index].checkproduct)"
                        >
                          <p class="txt-odrx mb-0">
                            {{ $t('checkOrder') }}
                          </p>
                        </b-form-checkbox>
                      </td>

                      <td
                        class=""
                        style="width: 10%;"
                      >
                        <b-form-checkbox
                          v-model="item.re_wrap"
                          name="check-button"
                          :value="true"
                          disabled
                          @change="UpdateDetail(details.shops[index].re_wrap)"
                        >
                          <p class="txt-odrx mb-0">
                            {{ $t('repackage') }}
                          </p>
                        </b-form-checkbox>
                      </td>

                      <td
                        class=""
                        style="width: 10%;"
                      >
                        <b-form-checkbox
                          v-model="item.cashOndelivery"
                          name="check-button"
                          :value="true"
                          disabled
                          @change="UpdateDetail(details.shops[index].cashOndelivery)"
                        >
                          <p class="txt-odrx mb-0">
                            {{ $t('cashOndelivery') }}
                          </p>
                        </b-form-checkbox>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <table
                  style="width: 100%;"
                  class="table-width-1"
                >
                  <!-- colgroup -->
                  <colgroup>
                    <col style="width: 50%;">
                    <col style="width: 10%;">
                    <col style="width: 10%;">
                    <col style="width: 10%;">
                    <col style="width: 10%;">
                    <!-- <col style="width: 10%;"> -->
                  </colgroup>

                  <thead>
                    <tr style="background-color: #19875421; border-radius: 10px !important;">
                      <th class="p-1">
                        <div class="d-flex align-items-center">
                          <span> {{ index+1 }}. {{ item.storename }}</span>

                          <!-- <b-form-input
                          v-model="tag"
                          type="text"
                          size="sm"
                          class="ml-1 w-50"
                        /> -->
                        </div>
                      </th>

                      <th class="text-center">
                        {{ $t('quantity') }}
                      </th>

                      <th class="text-center">
                        {{ $t('PerItem') }} [¥]
                      </th>

                      <th class="text-center">
                        {{ $t('total') }} [¥]
                      </th>

                      <th class="text-center">
                        {{ $t('total') }} [฿]
                      </th>

                      <!-- <th class="text-center">
                      จัดการ
                    </th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(itemsproduct, indexx) in item.products"
                      :key="indexx"
                      class="anan-order-row"
                      :style="`background-color: ${itemsproduct.colorfield};`"
                    >
                      <td class="d-flex align-items-center p-1">
                        <span>{{ index+1 }}.{{ indexx + 1 }}</span>

                        <img
                          :src="itemsproduct.color_img ? itemsproduct.color_img : itemsproduct.picture"
                          alt="asd"
                          height="50px"
                          class="ml-2 rounded mr5"
                        >

                        <div>
                          <a
                            :href="itemsproduct.url"
                            target="_blank"
                          >
                            <h5 class="mb-0 JB57cn2">
                              {{ itemsproduct.name }}
                            </h5>
                          </a>
                          <p
                            v-if="itemsproduct.color_title"
                            class="mb-0"
                          >
                            {{ $t('color') }} : {{ itemsproduct.color_title ? itemsproduct.color_title: '-' }}
                          </p>
                          <p
                            v-if="itemsproduct.size_title"
                            class="mb-0"
                          >
                            {{ $t('size') }} : {{ itemsproduct.size_title ? itemsproduct.size_title: '-' }}
                          </p>
                          <p
                            v-if="itemsproduct.remark_product"
                            class="mb-0"
                          >
                            {{ $t('remarks') }} : {{ itemsproduct.remark_product ? itemsproduct.remark_product:
                              $t('none') }}
                          </p>
                          <div
                            v-if="itemsproduct.more_payment.remark || itemsproduct.more_payment.datas.length > 0"
                            class="text-danger"
                          >
                            <p
                              v-if="itemsproduct.more_payment.remark"
                              class="mb-0"
                            >
                              {{ $t('remarks') }} : {{ itemsproduct.more_payment.remark }}
                            </p>
                            <div v-if="itemsproduct.more_payment.datas.length > 0">
                              <p
                                v-for="(item_morepay, index_morepay) in itemsproduct.more_payment.datas"
                                :key="index_morepay"
                                class="mb-0"
                              >
                                {{ item_morepay.payment_type == 1 ? $t('saveMoremoney') : $t('Refunds') }} : {{
                                  Commas(item_morepay.amount) }} บาท
                              </p>
                              <p
                                v-if="itemsproduct.more_payment.datas.filter(ele => ele.payment_type == 1).reduce((acc, ele) => acc + (ele.amount), 0) > itemsproduct.more_payment.datas.filter(ele => ele.payment_type == 2).reduce((acc, ele) => acc + (ele.amount), 0)"
                                style="font-weight: 800;"
                              >
                                {{ $t('extraMoney') }} : {{ Commas(itemsproduct.more_payment.datas.filter(ele =>
                                ele.payment_type == 1).reduce((acc, ele) => acc + (ele.amount), 0) -
                                itemsproduct.more_payment.datas.filter(ele => ele.payment_type == 2).reduce((acc, ele)  => acc + (ele.amount), 0)) }} บาท
                              </p>
                              <p v-else>
                                {{ $t('totalRefunds') }} : {{ Commas(itemsproduct.more_payment.datas.filter(ele =>
                                ele.payment_type == 2).reduce((acc, ele) => acc + (ele.amount), 0) -
                                itemsproduct.more_payment.datas.filter(ele => ele.payment_type == 1).reduce((acc, ele) => acc + (ele.amount), 0)) }} บาท
                              </p>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td
                        class="text-center"
                        style="vertical-align: middle;"
                      >
                        <p class="mb-0">
                          {{ Commas(itemsproduct.quantity ? itemsproduct.quantity: 0) }}
                        </p>
                      </td>

                      <td
                        class="text-center"
                        style="vertical-align: middle;"
                      >
                        <p class="mb-0">
                          {{ itemsproduct.price ? Commas(Number(itemsproduct.price)) : '0.00' }}
                        </p>
                      </td>

                      <td
                        class="text-center"
                        style="vertical-align: middle;"
                      >
                        <p class="mb-0">
                          {{ Commas((itemsproduct.quantity * Number(itemsproduct.price))) }}
                        </p>
                      </td>

                      <td
                        class="text-center"
                        style="vertical-align: middle;"
                      >
                        <p class="mb-0">
                          {{ Commas(((itemsproduct.quantity * Number(itemsproduct.price))* details.rate_yuan )) }}
                        </p>
                      </td>

                      <!-- <td
                      class="text-center"
                      style="vertical-align: middle;"
                    >
                      <b-button
                        v-b-tooltip.hover.top="'ให้ลูกค้าชำระเงินก่อน!'"
                        variant="outline-primary"
                        size="sm"
                        class="btn-icon rounded-circle mr5 sq-30"
                      >
                        <i class="fas fa-sack-dollar" />
                      </b-button>

                      <b-button
                        variant="warning"
                        size="sm"
                        class="btn-icon rounded-circle mr5 sq-30"
                        @click="showRemark2(index,indexx)"
                      >
                        <i class="fas fa-comment-alt-exclamation" />
                      </b-button>

                      <b-button
                        variant="info"
                        size="sm"
                        class="btn-icon rounded-circle mr5 sq-30"
                        @click="showColor(index,indexx)"
                      >
                        <i class="fas fa-fill-drip" />
                      </b-button>
                    </td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <div class="card-info mt-1 table-width-1">
                  <div class="row">
                    <div class="col-md-8">
                      <!-- {{ item.postnumber }} -->
                      <span
                        v-for="ItPost in item.postnumber"
                        :key="ItPost"
                      >
                        <span
                          v-for="ItPostImg in ItPost.path_img"
                          :key="ItPostImg"
                          class="aws-avatar aws aws-avatar-square aws-avatar-image bordered cursor-pointer"
                        >
                          <img
                            :src="ItPostImg"
                            alt="asd"
                            class="rounded mr5"
                            @click="showPicture(ItPostImg)"
                          >
                        </span>
                      </span>
                    </div>

                    <div class="col-md-4">
                      <div>
                        <table style="width: 100%;">
                          <!-- colgroup -->
                          <colgroup>
                            <col style="width: 50%;">
                            <col style="width: 25%;">
                            <col style="width: 25%;">
                          </colgroup>

                          <tbody>
                            <tr>
                              <td>
                                <p class="mb-0 font-weight-bolder">
                                  {{ $t('totalProductprice') }}
                                </p>
                              </td>
                              <td>
                                <p class="mb-0 font-weight-bolder text-success">
                                  ¥ {{ Commas(item.products.reduce((acc, ele) => acc + (ele.price*ele.quantity), 0) ) }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="mb-0 font-weight-bolder text-primary">
                                  ฿ {{ Commas((item.products.reduce((acc, ele) => acc + (ele.price*ele.quantity), 0) *
                                    details.rate_yuan) ) }}
                                </p>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <p class="mb-0 font-weight-bolder">
                                  {{ $t('shippingInchina') }}

                                </p>
                              </td>
                              <td>
                                <p class="mb-0 font-weight-bolder text-success" />
                              </td>
                              <td class="text-right">
                                <p class="mb-0 font-weight-bolder text-primary">
                                  ฿ {{ item.shipping_cnfee ? Commas(item.shipping_cnfee * details.rate_yuan) : '0.00' }}
                                </p>
                              </td>
                            </tr>

                            <!-- <tr>
                              <td>
                                <p class="mb-0">
                                  {{ $t('woodenCrates') }} (฿)
                                </p>
                              </td>
                              <td>
                                <p class="mb-0 font-weight-bolder text-success" />
                              </td>
                              <td class="text-right">
                                <p class="mb-0 font-weight-bolder text-primary">
                                  ฿ {{ Commas(item.postnumber.reduce((acc, ele) => acc + (ele.packaging_fee), 0)) }}
                                </p>
                              </td>
                            </tr> -->

                            <!-- <tr>
                              <td>
                                <p class="mb-0">
                                  {{ $t('ProductInspectionfee') }} (฿)
                                </p>
                              </td>
                              <td>
                                <p class="mb-0 font-weight-bolder text-success" />
                              </td>
                              <td class="text-right">
                                <p class="mb-0 font-weight-bolder text-primary">
                                  ฿ {{ Commas(item.postnumber.reduce((acc, ele) => acc + (ele.product_inspection_fee),
                                                                     0)) }}
                                </p>
                              </td>
                            </tr> -->

                            <!-- <tr>
                              <td>
                                <p class="mb-0">
                                  {{ $t('newWrappingcost') }} (฿)
                                </p>
                              </td>
                              <td>
                                <p class="mb-0 font-weight-bolder text-success" />
                              </td>
                              <td class="text-right">
                                <p class="mb-0 font-weight-bolder text-primary">
                                  ฿ {{ Commas(item.postnumber.reduce((acc, ele) => acc + (ele.re_wrap_fee), 0)) }}
                                </p>
                              </td>
                            </tr> -->

                            <!-- <tr>
                              <td>
                                <p class="mb-0">
                                  {{ $t('cashOndelivery') }} (฿)
                                </p>
                              </td>
                              <td>
                                <p class="mb-0 font-weight-bolder text-success" />
                              </td>
                              <td class="text-right">
                                <p class="mb-0 font-weight-bolder text-primary">
                                  ฿ {{ Commas(item.cashOndelivery_fee ? item.cashOndelivery_fee.toFixed(2): '0.00') }}
                                </p>
                              </td>
                            </tr> -->
                            <tr>
                              <td>
                                <p class="mb-0 font-weight-bolder">
                                  {{ $t('totalPrice') }}
                                </p>
                              </td>
                              <td>
                                <p class="mb-0 font-weight-bolder text-success" />
                              </td>
                              <td class="text-right">
                                <p class="mb-0 font-weight-bolder text-primary">
                                  ฿ {{ Commas((item.products.reduce((acc, ele) => acc + (ele.price*ele.quantity), 0) *
                                    details.rate_yuan) + item.postnumber.reduce((acc, ele) => acc + (ele.packaging_fee),
                                                                              0)+ item.postnumber.reduce((acc, ele) => acc + (ele.product_inspection_fee),
                                                                                                         0)+item.postnumber.reduce((acc, ele) => acc + (ele.re_wrap_fee),
                                                                                                                                   0)+(Number(item.cashOndelivery_fee) + (Number(item.shipping_cnfee)* details.rate_yuan)
                                                                                                                                   )) }}
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <table style="width: 100%;">

                        <thead>
                          <tr>
                            <th class="p-1">
                              {{ $t('parcelCode') }}
                            </th>

                            <th class="text-center">
                              {{ $t('productLot') }}
                            </th>

                            <th class="text-center">
                              {{ $t('arrivedChinaWarehouse') }}
                            </th>

                            <th class="text-center">
                              {{ $t('leftChinaWarehouse') }}
                            </th>

                            <th class="text-center">
                              {{ $t('arrivedThaiWarehouse') }}
                            </th>

                            <th class="text-center">
                              {{ $t('dimensionsCm') }}
                            </th>

                            <th class="text-center">
                              {{ $t('weightKg') }}
                            </th>

                            <th class="text-center">
                              {{ $t('amount') }}
                            </th>

                            <th class="text-center">
                              {{ $t('calculateByKilo') }}(฿)
                            </th>

                            <th class="text-center">
                              {{ $t('queue') }}(฿)
                            </th>
                          </tr>
                        </thead>
                        <tbody
                          v-for="(itpost,indexP) in item.postnumber"
                          :key="indexP"
                        >
                          <tr>
                            <td>
                              <div>
                                <input
                                  v-model="itpost.postnumber"
                                  class="form-control mt-1"
                                  type="text"
                                  disabled
                                >
                              </div>
                            </td>
                            <td class="text-center">
                              {{ itpost.lot_name ? `${itpost.lot_name}/${itpost.lot_order}` : '-' }}
                            </td>
                            <td class="text-center">
                              {{ itpost.come_chinesewarehouse ? itpost.come_chinesewarehouse : '-' }}
                            </td>
                            <td class="text-center">
                              {{ itpost.out_chinesewarehouse ? itpost.out_chinesewarehouse : '-' }}

                            </td>
                            <td class="text-center">
                              {{ itpost.come_thaiwarehouse ? itpost.come_thaiwarehouse : '-' }}
                              <!-- {{ itpost.out_thaiwarehouse ? itpost.out_thaiwarehouse : '-' }} -->
                            </td>
                            <td class="text-center">
                              {{ itpost.width && itpost.long && itpost.height ? itpost.width + 'x' + itpost.long + 'x' +
                                itpost.height : '-' }}
                            </td>
                            <td class="text-center">
                              {{ itpost.weight ? Commas(itpost.weight) : '-' }}
                            </td>
                            <td class="text-center">
                              {{ itpost.charge_cue ? Commas(itpost.charge_cue) : '-' }}
                            </td>
                            <td class="text-center">
                              {{ itpost.charge_per_kilo ? Commas(itpost.charge_per_kilo) : '-' }}
                            </td>
                            <td class="text-center">
                              <!-- {{ itpost }} -->
                              {{ itpost.charge_per_cue ? Commas(itpost.charge_per_cue) : '-' }}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <div class="mt-2 d-flex align-items-center">
                        <p
                          class="mb-0 font-weight-bolder"
                          style="color: red;"
                        >
                          {{ $t('remarks') }} : {{ details.shops[index].remark_shops ?
                            details.shops[index].remark_shops: 'ไม่มี' }}
                        </p>

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 py-1">
              <div class="card-total">
                <p class="font-weight-bolder">
                  {{ $t('shopCount') }}
                </p>
                <h3 class="font-weight-bolder">
                  {{ Commas(details.shops.length) }}
                </h3>
              </div>
            </div>

            <div class="col-md-3 py-1">
              <div class="card-total">
                <p class="font-weight-bolder">
                  {{ $t('numberOfproducts') }}
                </p>
                <h3 class="font-weight-bolder">
                  {{ Commas(details.shops.reduce((acc, shop) => acc + (shop.products.length > 0 ?
                    shop.products.reduce((bf, ele) => bf + ele.quantity, 0) : 0), 0)) }}
                </h3>
              </div>
            </div>

            <div class="col-md-3 py-1">
              <div class="card-total">
                <p class="font-weight-bolder">
                  {{ $t('exRate') }}
                </p>
                <h3 class="font-weight-bolder">
                  {{ details.rate_yuan }}
                </h3>
              </div>
            </div>

            <div class="col-md-3 py-1">
              <div class="card-total">
                <p class="font-weight-bolder">
                  {{ $t('key-66') }} [¥ {{ $t('yuanAmount') }}]
                </p>
                <h3 class="font-weight-bolder">
                  ¥ {{ Commas(details.shops.reduce((acc, shop) => acc + (shop.products.reduce((bf, ele) => bf +
                    (ele.price * ele.quantity), 0)+shop.shipping_cnfee), 0)) }}
                </h3>
              </div>
            </div>

            <!-- <div class="col-md-3">
              <div class="card-total">
                <p class="font-weight-bolder">
                  ยอดรวมสินค้าทั้งหมด [฿ เงินบาท]
                </p>
                <h3 class="font-weight-bolder">
                  ฿ {{ Commas(details.shops.reduce((acc, shop) => acc + (shop.products.reduce((bf, ele) => bf + (ele.price * ele.quantity), 0)), 0) * details.rate_yuan ) }}
                </h3>
              </div>
            </div> -->
          </div>

          <div class="row mt-2">
            <div class="col-md-6" />
            <div class="col-md-6">
              <div class="card-info">
                <h4 class="font-weight-bolder">
                  {{ $t('summaryListofexpenses') }}
                </h4>
                <hr>

                <!-- <div>
                  <h4 class="font-weight-bolder">
                    {{ $t('specialServicecosts') }}
                  </h4>

                  <div class="d-flex justify-content-between pl-2 pr-2">
                    <p class="mb-0">
                      {{ $t('shippingInchina') }} ({{ $t('baht') }})
                    </p>

                    <p class="font-weight-bolder">
                      ฿ {{ Commas(shipping_cnfee = details.shops.reduce((acc, shop) => acc + shop.shipping_cnfee, 0) *
                        details.rate_yuan ) }}
                    </p>
                  </div>

                  <div class="d-flex justify-content-between pl-2 pr-2">
                    <p class="mb-0">
                      {{ $t('woodenCrates') }} ({{ $t('baht') }})
                    </p>

                    <p class="font-weight-bolder">
                      ฿ {{ Commas(packaging_fee = details.shops.map(shop => shop.postnumber.reduce((acc, shop) => acc +
                        shop.packaging_fee, 0)).reduce((re, res) => re + res,0) ) }}
                    </p>
                  </div>

                  <div class="d-flex justify-content-between pl-2 pr-2">
                    <p class="mb-0">
                      {{ $t('ProductInspectionfee') }} ({{ $t('baht') }})
                    </p>

                    <p class="font-weight-bolder">
                      ฿ {{ Commas(product_inspection_fee = details.shops.map(shop => shop.postnumber.reduce((acc, shop)  => acc + shop.product_inspection_fee, 0)).reduce((re, res) => re + res,0) ) }}
                    </p>
                  </div>

                  <div class="d-flex justify-content-between pl-2 pr-2">
                    <p class="mb-0">
                      {{ $t('newWrappingcost') }} ({{ $t('baht') }})
                    </p>

                    <p class="font-weight-bolder">
                      ฿ {{ Commas(re_wrap_fee = details.shops.map(shop => shop.postnumber.reduce((acc, shop) => acc +
                        shop.re_wrap_fee, 0)).reduce((re, res) => re + res,0) ) }}
                    </p>
                  </div>

                  <div class="d-flex justify-content-between pl-2 pr-2">
                    <p class="mb-0">
                      {{ $t('cashOndelivery') }} ({{ $t('baht') }})
                    </p>

                    <p class="font-weight-bolder">
                      ฿ {{ Commas(cashOndelivery_fee = details.shops.reduce((acc, shop) => acc +
                        shop.cashOndelivery_fee, 0) ) }}
                    </p>
                  </div>
                </div> -->

                <div class="d-flex justify-content-between">
                  <p>{{ $t('totalProductprice') }} ({{ $t('baht') }})</p>

                  <p class="font-weight-bolder">
                    ฿ {{ Commas(totalall=details.shops.reduce((acc, shop) => acc + (shop.products.reduce((bf, ele) => bf
                      + (ele.price * ele.quantity), 0)), 0) * details.rate_yuan ) }}
                  </p>
                </div>

                <p class="mb-0">
                  {{ $t('useDiscountCoupon') }} : {{ $t('none') }}
                </p>

                <hr>

                <div class="d-flex justify-content-between">
                  <h3 class="font-weight-bolder">
                    {{ $t('amountDue') }}
                  </h3>

                  <h3 class="font-weight-bolder text-primary">
                    {{ Commas( (shipping_cnfee)+(packaging_fee)+(product_inspection_fee)+(re_wrap_fee)+
                      (cashOndelivery_fee)+(totalall)) }} {{ $t('baht') }}
                  </h3>
                </div>
              </div>

            </div>
          </div>

        </div>
      </b-col>
    </b-row>

    <b-modal
      ref="my-modal"
      :title="$t('orderRate')"
      size="sm"
    >
      <b-form-group>
        <label for="rate">{{ $t('exRate') }}</label>
        <b-form-input
          id="rate"
          v-model="rate.rate"
          type="number"
          :placeholder="$t('exRate')"
        />
      </b-form-group>
    </b-modal>

    <b-modal
      ref="my-remark"
      :title="$t('key-140')"
      size="sm"
      @ok="UpdateDetail()"
    >
      <b-form-group>
        <label for="remark">{{ $t('remarks') }}</label>
        <b-form-textarea
          id="remark"
          v-model="remark_shops"
          type="text"
        />
      </b-form-group>
    </b-modal>

    <b-modal
      ref="my-remark2"
      :title="$t('key-144')"
      size="sm"
      @ok="UpdateDetail()"
    >
      <b-form-group>
        <label for="remark">{{ $t('remarks') }}</label>
        <b-form-textarea
          id="remark"
          v-model="remark_product"
          type="text"
        />
      </b-form-group>
    </b-modal>

    <b-modal
      ref="my-color"
      :title="$t('key-141')"
      size="sm"
      hide-footer
    >
      <div class="d-flex justify-content-center align-items-center">
        <div
          style="width: 50px; height: 50px; background-color: rgb(255, 255, 255); border: 1px solid#ccc;"
          class="mr-1"
          @click="selectedColor('rgb(255, 255, 255)')"
        />

        <div
          style="width: 50px; height: 50px; background-color: rgb(241, 241, 241);"
          class="mr-1"
          @click="selectedColor('rgb(241, 241, 241)')"
        />

        <div
          style="width: 50px; height: 50px; background-color: rgb(209, 231, 251);"
          class="mr-1"
          @click="selectedColor('rgb(209, 231, 251)')"
        />

        <div
          style="width: 50px; height: 50px; background-color: rgb(230, 253, 205);"
          class="mr-1"
          @click="selectedColor('rgb(230, 253, 205)')"
        />

        <div
          style="width: 50px; height: 50px; background-color: rgb(255, 231, 231);"
          class="mr-1"
          @click="selectedColor('rgb(255, 231, 231)')"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BModal,
  BLink,
  BBadge,
  BTable,
  // BFormSelect,
  BFormCheckbox,
  // BButton,
  VBTooltip,
} from 'bootstrap-vue'
import moment from 'moment-timezone'
// import vSelect from 'vue-select'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import 'vue-context/dist/css/vue-context.css'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BBadge,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BModal,
    // BFormSelect,
    BLink,
    BFormCheckbox,
    // BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      indexShop: null,
      indexProduct: null,
      checked: null,
      rate: null,
      color: null,
      name: '',
      category: '',
      code: '',
      details: '',
      type: null,
      typesOp: [
        { value: null, text: this.$t('all') },
        { value: '1', text: 'A' },
        { value: '2', text: 'B' },
        { value: '3', text: 'C' },
      ],
      remark_shops: '',
      remark_product: '',
      selectedFiles: [],
      uploadedImages: [],
      fieldsRelevant: [
        {
          key: 'created_at', label: this.$t('transactionDate'), thStyle: { width: '20%' }, formatter: value => moment(value).format('DD/MM/YYYY HH:mm:ss'),
        },
        { key: 'detail', label: this.$t('details'), thStyle: { width: '60%' } },
        { key: 'amount', label: this.$t('quantity'), thStyle: { width: '20%' } },
      ],
      itemsRelevant: [],
      fields: [
        { key: 'create_date', label: this.$t('transactionDate'), thStyle: { width: '15%' } },
        { key: 'detail', label: this.$t('details'), thStyle: { width: '70%' } },
        { key: 'amount', label: this.$t('quantity'), thStyle: { width: '15%' } },
      ],
      items: [
        { create_date: '00/00/00 00:00:00', detail: 'ฟหกฟหกฟหก', amount: '1' },
        { create_date: '00/00/00 00:00:00', detail: 'ฟหกฟหกฟหก', amount: '1' },
        { create_date: '00/00/00 00:00:00', detail: 'ฟหกฟหกฟหก', amount: '1' },
      ],
      itemRelevant: [],
      PostNumberArr: [],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
  },
  async mounted() {
    await this.GetRate()
    this.GetOrderDetail()
    this.GetTransaction()
    // console.log(this.userData)
  },
  methods: {
    Approve(data) {
      this.details.status = data + 1
      this.details.inspector = this.userData.name
      this.UpdateDetail()
    },
    UpdateDetail() {
      if (this.remark_shops && this.IndexRemark >= 0) {
        this.details.shops[this.IndexRemark].remark_shops = this.remark_shops
      }
      if (this.indexShop >= 0 && this.indexProduct >= 0 && this.remark_product) {
        this.details.shops[this.indexShop].products[this.indexProduct].remark_product = this.remark_product
      }
      const params = {
        data: this.details,
      }
      // console.log(params)
      this.$http
        .post('/order/UpdateOrderById', params)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.Success(this.$t('key-260'))
          this.GetOrderDetail()
          this.remark_shops = null
          this.IndexRemark = null
          this.indexShop = null
          this.indexProduct = null
          this.remark_product = null
          // console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    GetRate() {
      this.$http
        .get('/rate')
        .then(response => {
          // console.log(response.data)
          this.rate = response.data.rate_preorder
        })
        .catch(error => console.log(error))
    },
    async GetOrderDetail() {
      const params = {
        ID: this.$route.params.id,
      }
      this.$http
        .post('/order/OrderById', params)
        .then(response => {
          // console.log(response.data)
          this.details = response.data
          this.details.created_at = moment(response.data.created_at).format('YYYY-MM-DD HH:mm:ss')
          this.details.updated_at = moment(response.data.updated_at).format('YYYY-MM-DD HH:mm:ss')

          // eslint-disable-next-line
          for (const index in this.details.shops) {
            this.details.shops[index].postnumber = []
            this.CalPostNumber(this.details.shops[index], index)
          }
        })
        .catch(error => console.log(error))
    },
    CalPostNumber(item, ShopIndex) {
      const FindShop = this.PostNumberArr.find(ele => ele.ShopIndex === ShopIndex)
      if (FindShop) {
        this.details.shops[ShopIndex].postnumber = FindShop.item
      } else {
        this.GetPostNumber(item, ShopIndex)
      }
    },
    async GetPostNumber(item, ShopIndex) {
      try {
        const params = {
          // eslint-disable-next-line no-underscore-dangle
          shop_id: item._id,
        }
        const { data: res } = await this.$http.get('/postnumber/byshop', { params })
        if (res.success) {
          this.details.shops[ShopIndex].postnumber = res.data
          this.details = { ...this.details }
          const obj = { ShopIndex, item: res.data }
          this.PostNumberArr.push(obj)
        }
      } catch (error) {
        console.log(error)
      }
      return []
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-219')}!</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    async GetTransaction() {
      try {
        const params = {
          ref_id: this.$route.params.id,
        }
        const { data: res } = await this.$http.get('/transactions/show/ref', { params })
        if (res.success) {
          this.itemRelevant = res.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    getAmountStyle(amount, typeAmount) {
      if (amount === 21) {
          if (typeAmount) {
            return { color: 'green' }
          } else {
            return { color: 'red' }
          }
        } else {
            if ([1, 8, 11, 12, 17, 16].includes(amount)) {
              return { color: 'green' }
            }
            if ([2, 3, 4, 5, 6, 7, 13, 15, 18, 10, 9, 20].includes(amount)) {
              return { color: 'red' }
            }
        }
    },
    getAmountPrefix(value, typeAmount) {
      if (value === 21) {
          if (typeAmount) {
            return '+'
          } else {
            return '-'
          }
        } else {
            if ([1, 8, 11, 12, 17, 16].includes(value)) {
              return '+'
            }
            if ([2, 3, 4, 5, 6, 7, 13, 15, 18, 10, 9, 20].includes(value)) {
                return '-'
            }
        }
    },
    ChangePostNumber(indexShop, postnumber) {
      this.details.shops[indexShop].postnumber = postnumber
      this.UpdateDetail()
    },
    showColor(indexShop, indexProduct) {
      // colorfield
      this.indexShop = indexShop
      this.indexProduct = indexProduct
      this.$refs['my-color'].show()
    },
    selectedColor(color) {
      // change color by index
      this.details.shops[this.indexShop].products[this.indexProduct].colorfield = color
      this.UpdateDetail()
      this.$refs['my-color'].hide()
    },
    addAddr() {
      this.$refs.addRules.validate().then(success => {
        if (success) {
          this.$bvModal.hide('add-addr')
        }
      })
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    showRemark(index) {
      this.remark_shops = ''
      this.IndexRemark = index
      if (this.details.shops[this.IndexRemark].remark_shops) {
        this.remark_shops = this.details.shops[this.IndexRemark].remark_shops
      }
      this.$refs['my-remark'].show()
    },
    showRemark2(indexShop, indexProduct) {
      this.remark_product = ''
      this.indexShop = indexShop
      this.indexProduct = indexProduct
      if (this.details.shops[indexShop].products[indexProduct].remark_product) {
        this.remark_product = this.details.shops[indexShop].products[indexProduct].remark_product
      }
      this.$refs['my-remark2'].show()
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-context.scss';
</style>
